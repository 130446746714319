<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
          <status class="small" v-bind="{item}" />
        </h1>
        
        <action-bar v-bind="{item,resource}" @action="action" />

        <div class="row row-cols-1 row-cols-md-2 mt-4">
          <div class="col mb-4">
            <b-card>
              <template #header> 
                <i :class="['bx', resource.icon]" />
                {{$t('batch_items.label')}}
              </template>
              <dl>
                <div class="row row-cols-1 row-cols-xl-2">
                  <div class="col">
                    <value label="common.name" icon="bx-purchase-tag" :value="item.name"/>
                  </div>
                  <!-- <div class="col">
                    <value label="common.country" icon="bx-globe" :value="item.country">
                      <country :country="item.country" />
                    </value>
                  </div> -->
                  <!-- <div class="col">
                    <value label="common.locale" icon="bx-message-square-detail" :value="$t(`locale.${item.locale}`)" />
                  </div> -->
                  <div class="col">
                    <value label="common.phone" icon="bx-phone" :value="'+'+item.full_phone_number"/>
                  </div>
                  <div class="col">
                    <value label="common.email" icon="bx-envelope" :value="item.email"/>
                  </div>
                </div>

              </dl>
            </b-card>

            <b-card v-if="creditCards">
              <template #header> 
                <i class="bx bxs-credit-card" />
                {{$t('batch_items.credit-card')}}
              </template>
              <dl>
                <value label="batch_items.expiry-date" icon="bx-calendar" :value="item.offset_date | moment('LL')"/>
              </dl>
            </b-card>

            <b-card>
              <template #header>
                <i class="bx bxs-message-detail"></i>
                {{ $t("batches.notifications") }}
              </template>
                <dl>
                  <value v-if="item.last_notified_at" label="batch_items.last-notified-at" icon="bx-message-detail" :value="item.last_notified_at | moment('LLLL')"/>
                  <value v-if="item.reminders_sent" label="batch_items.reminders-sent" icon="bx-message-error" :value="item.reminders_sent"/>
                  <value v-if="notificationText" label="batch_items.next-notification" icon="bx-message-add" :value="notificationText"/>
                </dl>
            </b-card>
          </div>
          <div class="col mb-4">
            <b-card>
              <template #header> 
                <!-- <div v-if="item.shipment_id" class="float-right pr-2">
                    <router-link :to="{name: `shipments-show`, params: {id: item.shipment_id}}">
                      {{ $t('batch_items.created-shipment')}}
                    </router-link>
                </div> -->
                <i class='bx bxs-truck' />
                {{$t('shipments.label')}}
              </template>
              <dl class="mb-0">
                <value label="shipments.external-reference" icon="bxs-purchase-tag" :value="item.external_reference_id"/>
                <value label="batch_items.pickup-location" icon="bx-map" :value="true">
                    <router-link v-if="item.dropoff_location_id" :to="{name: `locations-show`, params: {id: item.dropoff_location_id}}">
                      {{item.dropoff_location_name}}
                    </router-link>
                    <span class="no-value" v-else>Not responded</span>
                </value>
                <value v-if="item.shipment_id" label="batch_items.created-shipment" icon="bxs-truck" :value="true">
                    <router-link :to="{name: `shipments-show`, params: {id: item.shipment_id}}">
                      {{item.name}}
                    </router-link>
                </value>
              </dl>
            </b-card>

            <activity-card :item="item" />
          </div>
        </div>
      </div>
      <debug v-bind="{item,data: {parent}}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemShow from '@/components/ItemShow'
import Value from "@/components/Value";
import ActionBar from '@/components/ActionBar.vue'
import ActivityCard from '@/components/Cards/Activity'
import Status from '@/components/Status'
import { FEATURE_BATCH_CREDIT_CARDS } from '@/resources/Merchant';
import { STATUS_PENDING, STATUS_NOTIFIED, STATUS_REMINDED } from '@/resources/BatchItem';
import moment from 'moment';

export default {
  extends: ItemShow,
  components: { 
    MainLayout,
    Value,
    ActionBar,
    ActivityCard,
    Status
  },
  data() {
    return {
      STATUS_PENDING, 
      STATUS_NOTIFIED, 
      STATUS_REMINDED
    }
  },
  computed: {
    creditCards() {
      return !!(this.parent?.features & FEATURE_BATCH_CREDIT_CARDS)
    },
    notificationText() {
      switch(this.item.status) {
        case STATUS_PENDING:
          if(this.creditCards) {
            return moment(this.item.offset_date).subtract(this.parent.settings.offset,'days').format('LL')
          } else {
            return this.$t('batch_items.next-run')
          }
        case STATUS_NOTIFIED:
        case STATUS_REMINDED:
          if(this.item.reminders_sent < this.parent?.settings.reminder_count) {
            return moment(this.item.last_notified_at).add(this.parent?.settings.reminder_interval,'days').format('LL')
          } else {
            return this.$t('batch_items.stopped')
          }
        default:
          return false
      }
    }
  }
};
</script>